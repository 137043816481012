// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminCalendar as AdminCalendarContainer,
} from "@containers";

/**
 * AdminCalendar
 */
const AdminCalendar = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Vakantiedagen">
            <AdminCalendarContainer id="beheer-inactiviteitskalender" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdminCalendar);
